<template>
  <div class="page">
    <div class="initAccount">
      <b-card>
        <form-wizard
          color="#7367F0"
          :title="null"
          :subtitle="null"
          shape="square"
          finish-button-text="Submit"
          back-button-text="Previous"
          class="mb-3"
          @on-complete="formSubmitted"
        >
          <!-- accoint details tab -->
          <!-- <tab-content
            title="Account Details"
            :before-change="validationForm"
          >
            <validation-observer
              ref="accountRules"
              tag="form"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    Account Details
                  </h5>
                  <small class="text-muted">
                    Enter Your Account Details.
                  </small>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Username"
                    label-for="name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="username"
                      rules="required"
                    >
                      <b-form-input
                        id="name"
                        v-model="name"
                        :state="errors.length > 0 ? false:null"
                        placeholder="johndoe"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Email"
                    label-for="email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="email"
                        v-model="emailValue"
                        type="email"
                        :state="errors.length > 0 ? false:null"
                        placeholder="john.doe@email.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Password"
                    label-for="password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      vid="Password"
                      rules="required|password"
                    >
                      <b-form-input
                        id="password"
                        v-model="PasswordValue"
                        type="password"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Password"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Confirm Password"
                    label-for="c-password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Password Confirm"
                      rules="required|confirmed:Password"
                    >
                      <b-form-input
                        id="c-password"
                        v-model="passwordCon"
                        type="password"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Re-type Password"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content> -->

          <!-- personal details tab -->
          <tab-content
            title="Billing Information"
            :before-change="validationFormInfo"
          >
            <validation-observer
              ref="billingInfo"
              tag="form"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-2">
                    Billing Information
                  </h5>

                  <BillingInputs
                    @update-data="updateBilling"
                  />
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
          <tab-content
            title="Subscription Information"
            :before-change="validationFormInfo"
          >
            <validation-observer
              ref="planInfo"
              tag="form"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-2">
                    Subscription Plan
                  </h5>

                  <SubscriptionInputs
                    @update-data="updateSubscription"
                  />
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
        </form-wizard>
      </b-card>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
//  ValidationProvider,
import { ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BCard,
  BRow,
  BCol,
  // BFormGroup,
  // BFormInput,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import BillingInputs from '../components/Billing/BillingInputs.vue'
import SubscriptionInputs from '../components/Subscription/SubscriptionInputs.vue'

// import { codeIcon } from './code'

export default {
  components: {
    // ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BCard,
    // BFormGroup,
    // BFormInput,
    BillingInputs,
    SubscriptionInputs,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      selectedContry: '',
      selectedLanguage: '',
      name: '',
      emailValue: '',
      PasswordValue: '',
      passwordCon: '',
      first_name: '',
      last_name: '',
      address: '',
      landMark: '',
      pincode: '',
      twitterUrl: '',
      facebookUrl: '',
      googleUrl: '',
      linkedinUrl: '',
      city: '',
      required,
      email,
      billingInformation: {},
      subscriptionInformation: '',
      // codeIcon,
      countryName: [
        { value: 'select_value', text: 'Select Value' },
        { value: 'Russia', text: 'Russia' },
        { value: 'Canada', text: 'Canada' },
        { value: 'China', text: 'China' },
        { value: 'United States', text: 'United States' },
        { value: 'Brazil', text: 'Brazil' },
        { value: 'Australia', text: 'Australia' },
        { value: 'India', text: 'India' },
      ],
      languageName: [
        { value: 'nothing_selected', text: 'Nothing Selected' },
        { value: 'English', text: 'English' },
        { value: 'Chinese', text: 'Mandarin Chinese' },
        { value: 'Hindi', text: 'Hindi' },
        { value: 'Spanish', text: 'Spanish' },
        { value: 'Arabic', text: 'Arabic' },
        { value: 'Malay', text: 'Malay' },
        { value: 'Russian', text: 'Russian' },
      ],
    }
  },
  methods: {
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.billingInfo.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    updateBilling(data) {
      this.billingInformation = data
    },
    updateSubscription(data) {
      this.subscriptionInformation = data
    },
  },
}
</script>

<style lang="scss">

.initAccount {
  padding:35px;
}
</style>
